import { Injectable } from '@angular/core';
import {
  requestLoading,
  RequestLoadingType,
} from '@ieCore/helpers/request-loading.helper';
import { OrganizationRepository } from '@ieCore/repositories/organization.repository';
import {
  CreateOrgDto,
  OrgResponseDto,
  UpdateEnumDto,
  UpdateOrgDto,
} from 'shared-general-libs/dto/org';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import { PaginationInterface } from '@ieShared/interfaces/pagination.interface';
import { EnumDetailsResponseDto } from 'shared-general-libs/dist/dto/org';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  enumTranslationList: { [p: string]: string } = {};

  constructor(private orgRepo: OrganizationRepository) {}

  getCompanies(
    params: PaginationInterface,
  ): RequestLoadingType<PaginatedResponseDto<OrgResponseDto>> {
    return requestLoading(this.orgRepo.getCompanies(params));
  }

  getCompany(companyId: string): RequestLoadingType<OrgResponseDto> {
    return requestLoading(this.orgRepo.getCompany(companyId));
  }

  getEnums(companyId: string): RequestLoadingType<EnumDetailsResponseDto[]> {
    return requestLoading(this.orgRepo.getEnums(companyId));
  }

  getEnumTranslationList(): RequestLoadingType<{ [p: string]: string }> {
    return requestLoading(this.orgRepo.getEnumTranslationList());
  }

  updateEnums(
    id: string,
    enumId: string,
    keyPair: UpdateEnumDto,
  ): RequestLoadingType<EnumDetailsResponseDto> {
    return requestLoading(this.orgRepo.updateEnums(id, enumId, keyPair));
  }

  createCompany(data: CreateOrgDto): RequestLoadingType<OrgResponseDto> {
    return requestLoading(this.orgRepo.createCompany(data));
  }

  updateCompany(
    companyId: string,
    data: UpdateOrgDto,
  ): RequestLoadingType<OrgResponseDto> {
    return requestLoading(this.orgRepo.updateCompany(companyId, data));
  }

  deleteCompany(companyId: string): RequestLoadingType<void> {
    return requestLoading(this.orgRepo.deleteCompany(companyId));
  }
}
