import { environment } from '@ieEnv/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CreateOrgDto,
  OrgResponseDto,
  UpdateOrgDto,
} from 'shared-general-libs/dto/org';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import { PaginationInterface } from '@ieShared/interfaces/pagination.interface';
import { removeEmptyFields } from '@ieShared/utils/query.utils';
import {
  EnumDetailsResponseDto,
  UpdateEnumDto,
} from 'shared-general-libs/dist/dto/org';

@Injectable({
  providedIn: 'root',
})
export class OrganizationRepository {
  private readonly apiUrl: string = environment.organizationHost;

  constructor(private http: HttpClient) {}

  getCompanies(
    params: PaginationInterface,
  ): Observable<PaginatedResponseDto<OrgResponseDto>> {
    return this.http.get<PaginatedResponseDto<OrgResponseDto>>(
      `${this.apiUrl}/orgs`,
      { params: removeEmptyFields(params) },
    );
  }

  getCompany(companyId: string): Observable<OrgResponseDto> {
    return this.http.get<OrgResponseDto>(`${this.apiUrl}/orgs/${companyId}`);
  }

  getEnums(companyId: string): Observable<EnumDetailsResponseDto[]> {
    return this.http.get<EnumDetailsResponseDto[]>(
      `${this.apiUrl}/enum/${companyId}`,
    );
  }

  getEnumTranslationList(): Observable<{ [p: string]: string }> {
    return this.http.get<{ [p: string]: string }>(
      `${this.apiUrl}/enum/translations`,
    );
  }

  updateEnums(
    orgId: string,
    enumId: string,
    keyPairs: UpdateEnumDto,
  ): Observable<EnumDetailsResponseDto> {
    return this.http.put<EnumDetailsResponseDto>(
      `${this.apiUrl}/enum/${orgId}/${enumId}`,
      keyPairs,
    );
  }

  createCompany(data: CreateOrgDto): Observable<OrgResponseDto> {
    return this.http.post<OrgResponseDto>(`${this.apiUrl}/orgs`, data);
  }

  updateCompany(
    companyId: string,
    data: UpdateOrgDto,
  ): Observable<OrgResponseDto> {
    return this.http.put<OrgResponseDto>(
      `${this.apiUrl}/orgs/${companyId}`,
      data,
    );
  }

  deleteCompany(companyId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/orgs/${companyId}`);
  }
}
